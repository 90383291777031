@import '../../css/utils/utils';

.wrap {
	cursor: pointer;
	opacity: 0.7;
	transition: all 0.2s ease-in-out;

	&:hover {
		opacity: 1;
	}
}

.before,
.after {
	position: absolute;
	left: 0;
	content: ' ';
	width: 0.15em;
	margin: 0 auto;
	right: 0;
}

.before {
	transform: rotate(45deg);
}

.after {
	transform: rotate(-45deg);
}
