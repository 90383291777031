@font-face {
	font-family: 'Roboto';
	src: url('./fonts/Roboto-Regular.eot');
	src:
		url('./fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
 url('./fonts/Roboto-Regular.woff2') format('woff2'),
		url('./fonts/Roboto-Regular.woff') format('woff'),
 url('./fonts/Roboto-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('./fonts/Roboto-Italic.eot');
	src:
		url('./fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
 url('./fonts/Roboto-Italic.woff2') format('woff2'),
		url('./fonts/Roboto-Italic.woff') format('woff'),
 url('./fonts/Roboto-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('./fonts/Roboto-Bold.eot');
	src:
		url('./fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
 url('./fonts/Roboto-Bold.woff2') format('woff2'),
		url('./fonts/Roboto-Bold.woff') format('woff'),
 url('./fonts/Roboto-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Oswald';
	src: url('./fonts/Oswald-Light.eot');
	src:
		url('./fonts/Oswald-Light.eot?#iefix') format('embedded-opentype'),
 url('./fonts/Oswald-Light.woff2') format('woff2'),
		url('./fonts/Oswald-Light.woff') format('woff'),
 url('./fonts/Oswald-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Oswald';
	src: url('./fonts/Oswald-Regular.eot');
	src:
		url('./fonts/Oswald-Regular.eot?#iefix') format('embedded-opentype'),
 url('./fonts/Oswald-Regular.woff2') format('woff2'),
		url('./fonts/Oswald-Regular.woff') format('woff'),
 url('./fonts/Oswald-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Oswald';
	src: url('./fonts/Oswald-Bold.eot');
	src:
		url('./fonts/Oswald-Bold.eot?#iefix') format('embedded-opentype'),
 url('./fonts/Oswald-Bold.woff2') format('woff2'),
		url('./fonts/Oswald-Bold.woff') format('woff'),
 url('./fonts/Oswald-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
