@import '../../css/utils/utils';

.section {
	background-color: $blueDarkest;
	position: relative;
}

.inner {
	@extend .py-4;
	@extend .py-md-5;
	@extend .py-lg-6;
	@extend .py-xl-7;
}

.row {
	align-items: center;
	display: flex;
	flex-wrap: wrap;

	@include media-breakpoint-up(md) {
		flex-wrap: nowrap;
	}
}

.col {
	margin-bottom: 20px;
	width: 100%;

	@include media-breakpoint-up(md) {
		width: 50%;

		&:first-child {
			margin-right: 56px;
		}
	}
}

.imageRow {
	@include noList();

	align-items: center;
	display: flex;
	justify-content: space-between;

	&:not(:last-child) {
		margin-bottom: 50px;
	}
}

.imageCol {
	text-align: center;
	width: 50%;

	&:first-child {
		margin-right: 15px;

		@include media-breakpoint-up(sm) {
			margin-right: 50px;
		}

		@include media-breakpoint-up(lg) {
			margin-right: 100px;
		}
	}
}

.image {
	img {
		height: auto;
		max-width: 100%;
		margin: 0 auto;
	}
}
