@import '../../css/utils/utils';

.section {
	display: block;
	position: relative;
}

.background {
	@include absoluteFull();
	background-size: cover;
	background-position: center top;
	z-index: -3;
}

.overlay {
	@include absoluteFull();
	background-color: rgba(0, 0, 0, 0.82);
	z-index: -3;
}

.inner {
	@extend .py-sm-4;
	@extend .py-md-5;
	@extend .py-lg-6;
	@extend .py-xl-7;
}

.heading {
	@extend .mb-4;
	@extend .mb-md-0;
	@extend .pr-md-4;

	@include media-breakpoint-up(sm) {
		width: 100%;
	}
}

.aboutHeading {
	@include headingBrand(40, 45, 37, 52, 63);
}

.text {
	@include fontSize(15, 23);
	color: $white;

	@include media-breakpoint-up(md) {
		@include fontSize(16, 24);
		max-width: 585px;
	}

	@include media-breakpoint-up(lg) {
		@include fontSize(18, 28);
	}
}

.iconBlocks {
	@include noList();
	@extend .mt-4;
	@extend .mt-md-5;
	@extend .mt-lg-6;
	display: flex;
	flex-wrap: wrap;

	@include media-breakpoint-up(md) {
		flex-wrap: nowrap;
		justify-content: space-between;
	}
}

.iconBlock {
	text-align: center;
	margin: 0 0 43px;
	width: 50%;

	@include media-breakpoint-up(sm) {
		width: calc(100% / 3);
	}

	@include media-breakpoint-up(md) {
		width: auto;
	}
}

.icon {
	max-width: 133px;
	margin: 0 auto;

	span {
		@include fontSize(58, 58);
		color: $blue;
		display: block;
		margin-bottom: $spacingUnit / 5;
	}

	p {
		@include fontSize(15, 20);
		color: $white;
		font-weight: 700;
	}
}

.counters {
	margin: 0;
	@extend .mt-sm-5;
	@extend .mt-lg-6;
}
