@import '../../css/utils/utils.scss';

// @mixin line() {
// 	border-radius: 4px;
// 	background-color: $white;
// 	position: absolute;
// 	width: 16px;
// 	height: 3px;
// 	left: 0;
// 	margin: 0 auto;
// 	right: 0;
// 	transition-timing-function: ease;
// 	transition-duration: 0.15s;
// 	transition-property: transform;

// 	@include media-breakpoint-up(md) {
// 		width: 18px;
// 	}
// }

.hamburg {
	background-color: $blue;
	font: inherit;
	display: inline-block;
	overflow: visible;
	margin: 0;
	cursor: pointer;
	transition-timing-function: linear;
	transition-duration: 0.15s;
	transition-property: background-color, filter;
	text-transform: none;
	color: inherit;
	border: 0;

	&:hover {
		background-color: rgba($blue, 0.7);
	}
}

// .hamburgActive {
// 	.inner {
// 		transition-delay: 0.22s;
// 		background-color: transparent !important;
// 	}

// 	.inner::before {
// 		top: 0;
// 		transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
// 		transform: translate3d(0, 7px, 0) rotate(45deg);
// 		@include media-breakpoint-up(md) {
// 			transform: translate3d(0, 8px, 0) rotate(45deg);
// 		}
// 	}

// 	.inner::after {
// 		top: 0;
// 		transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
// 		transform: translate3d(0, 7px, 0) rotate(-45deg);

// 		@include media-breakpoint-up(md) {
// 			transform: translate3d(0, 8px, 0) rotate(-45deg);
// 		}
// 	}
// }

.box {
	@include fontSize(20, 20);
	align-items: center;
	position: relative;
	display: flex;
	height: 33px;
	justify-content: center;
	opacity: 1;
	transition: opacity 0.15s ease-in-out;
	width: 33px;

	@include media-breakpoint-up(md) {
		height: 45px;
		width: 45px;
	}
}

// .inner {
// 	@include line();
// 	top: 12px;
// 	transition: background-color 0s linear 0.13s;
// 	display: block;
// 	margin-top: -2px;

// 	@include media-breakpoint-up(md) {
// 		top: 16px;
// 	}

// 	&::before,
// 	&::after {
// 		display: block;
// 		content: '';
// 		@include line();
// 	}

// 	&::before {
// 		top: 6px;
// 		transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);

// 		@include media-breakpoint-up(md) {
// 			top: 8px;
// 		}
// 	}

// 	&::after {
// 		top: 13px;
// 		transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);

// 		@include media-breakpoint-up(md) {
// 			top: 16px;
// 		}
// 	}
// }
