@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/utilities';

@import '~swiper/dist/css/swiper.css';

@import './plugins/fontawesome/css/all.css';
@import './plugins/normalize.css';
@import './plugins/icomoon/style.css';
@import './utils/utils.scss';
@import './utils/fonts.scss';
@import './utils/forms.scss';
@import './utils/buttons.scss';

html,
body {
	min-height: 100vh;
	width: 100%;
}

body {
	background-color: $blueDarkest;
	color: $white;
	margin: 0;
	padding: 0;
	position: relative;
	font-family:
		$fontBase,
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Cantarell',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// hack to get responsive breakpoint to show up in chrome breakpoint debugger
@media only screen and (max-width: 414px) {
	* {
		box-sizing: border-box;
	}
}


body.has-background-attachment {
	[data-ba-fixed='true'] {
		background-attachment: fixed;
	}
}

body.offmenu-open {
	overflow: hidden;
}

* {
	box-sizing: border-box;
}

#root {
	min-height: 100vh;
	padding-bottom: $footerHeight + 1px;
	position: relative;
	width: 100%;
}

.page-transition {
	will-change: opacity;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $fontHeading;
	font-weight: 700;
	text-transform: uppercase;
}

h1 {
	@include fontSize(70, 80);
	margin: rem(12) 0 rem(24);
}

h2 {
	@include fontSize(40, 45);
	margin: rem(12) 0 rem(24);
}

h3 {
	@include fontSize(30, 35);
	margin: rem(12) 0 rem(24);
}

h4 {
	@include fontSize(24, 29);
	margin: rem(12) 0 rem(24);
}

h5 {
	@include fontSize(19, 24);
	margin: rem(12) 0 rem(24);
}

a {
	color: inherit;
	text-decoration: none;

	&:focus,
	&:active,
	&:hover {
		color: inherit;
		text-decoration: none;
	}
}

img {
	height: auto;
	max-width: 100%;
}
