$black: #000E24;
$white: #FFF;
$grey: #C8C9CB;
$greyDark: #565D57;
$blue: #0091D6;
$blueDark: #001535;
$blueDarkest: #000E24;
$green: #C5FC01;

$fontBase: Roboto;
$fontHeading: Oswald;

$headerHeight: 124px;
$headerHeightMobile: 52px;

$footerHeight: 70px;

$spacingUnit: 1em;
$spacingUnitPx: 1em * 0.05;
$spacingUnitXs: $spacingUnit * 0.5;
$spacingUnitSm: $spacingUnit * 0.75;
$spacingUnitMd: $spacingUnit * 1.25;
$spacingUnitLg: $spacingUnit * 2;
$spacingUnitXl: $spacingUnit * 3.25;
$spacingUnitXxl: $spacingUnit * 5.25;
