@import '../../css/utils/utils.scss';

.section {
	position: relative;
}

.background {
	@include absoluteFull();
	background-size: cover;
	background-position: center;
	opacity: 0.23;
	z-index: -3;

	@include media-breakpoint-up(md) {
		position: absolute;
	}
}

.overlay {
	@include absoluteFull();
	background-color: $black;
	z-index: -3;
}

.header {
	@extend .mb-5;
	@extend .mb-md-7;
}

.question {
	cursor: pointer;
	@extend .mb-md-3;
	transition: all 0.2s ease-in-out;

	span {
		@include fontSize(18, 26);
		@extend .pb-2;
		font-family: $fontHeading;
		position: relative;

		&::after {
			background-color: $green;
			bottom: 0;
			content: ' ';
			height: 3px;
			left: 0;
			position: absolute;
			transition: width 0.2s ease-in-out;
			width: 0;
		}

		@include media-breakpoint-up(md) {
			@include fontSize(22, 32);
		}
	}
}

.answer {
	@include fontSize(15, 23);

	@include media-breakpoint-up(md) {
		@include fontSize(18, 28);
	}
}

.questionActive {
	@extend .mb-3;

	span {
		&::after {
			width: 75%;
		}
	}
}

.questionList {
	@include noList();
}

.accordionList {
	@include noList();
}

.accordionItem {
	&:not(:last-child) {
		@extend .mb-4;
	}

	.answer {
		overflow: hidden;
		max-height: 0;
		transition: all 0.2s ease-in-out, visibility 0.2s 0.2s;
		visibility: hidden;
	}
}

.accordionItemActive {
	.answer {
		max-height: 1000px;
		transition: all 0.2s ease-in-out;
		visibility: visible;
	}
}

.allFaqLink {
	@extend .mt-5;
	@extend .mt-md-6;
}

.allLink {
	@include arrowLink();
	@include fontSize(16, 20);

	@include media-breakpoint-up(md) {
		@include fontSize(18, 24);
	}
}

.cta {
	@extend .mt-5;
	@extend .mt-md-8;
}

.ctaInner {
	align-items: center;
	display: flex;
	flex-wrap: wrap;

	@include media-breakpoint-up(md) {
		flex-wrap: nowrap;
	}
}

.ctaContent {
	@extend .mb-3;
	@extend .pr-md-8;
	width: 100%;

	@include media-breakpoint-up(md) {
		width: calc(100% - 231px);
	}

	@include headings() {
		margin: 0;
	}

	h1,
	h2 {
		@include fontSize(40, 60);
		margin: 0 0 7px;
		font-weight: 400;
	}

	h3,
	h4,
	h5,
	h6 {
		@include fontSize(27, 37);
		font-weight: 300;

		strong {
			font-weight: 700;
		}
	}
}

.ctaButton {
	@extend .mb-3;
	width: 231px;

	> a {
		display: block;
		font-weight: 400;
		width: 100%;
	}
}

[data-layout='accordion'] {
	.accordion {
		max-width: 710px;
		margin: 0 auto;
	}
}
