@import '../../css/utils/utils.scss';

.section {
	background-color: $blueDarkest;
	position: relative;
}

.heading {
	@extend .mb-4;
}

.copy,
.copy p {
	@include fontSize(18, 28);
}

.copy {
	@extend .mb-4;
	@extend .mb-lg-0;
}

.video {
	max-width: 400px;
	margin: 0 auto;

	@include media-breakpoint-up(lg) {
		max-width: 609px;
		margin: 0;
	}
}

.slider {
	@extend .mt-5;
	@extend .mt-md-6;
	@extend .mt-lg-7;
}
