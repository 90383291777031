@import '../../css/utils/utils';

.title {
	@include fontSize(35, 52);
	@include titleUnderline(75%, 232px, $spacingUnitXs);
	text-align: center;
	text-transform: uppercase;
}

.accent {
	color: $green;
}
