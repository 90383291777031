@import '../../css/utils/utils.scss';

.wrap {
	position: fixed;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
	@include zIndex(modalWrap);

	&[data-size="large"] {
		.modal {
			height: 80%;
			width: 80%;

			@include media-breakpoint-up(md) {
				width: 75%;
			}
		}
	}
}

.modal {
	// display: none;
	position: fixed;
	left: 0;
	right: 0;
	padding: 0;
	max-height: 85%;
	width: 80%;
	margin: auto;
	overflow: hidden;
	border-radius: 2px;
	will-change: top, opacity;
	@include zIndex(modal);

	@include media-breakpoint-up(md) {
		width: 55%;
	}

	h1,
	h2,
	h3,
	h4 {
		margin-top: 0;
	}
}

.full {
	@extend .modal;
	background-color: transparent;
	width: 100%;
	height: 100%;
	max-height: 100%;
}

.modalInner {
	height: 100%;
	position: relative;
	// padding: 40px 0;
	width: 100%;

	// @include media-breakpoint-up(md) {
	// 	padding: 45px 0;
	// }
}

.modalScroll {
	height: 100%;
	overflow-y: auto;
}

.close {
	color: $white;
	cursor: pointer;
	display: block;
	font-size: 40px;
	height: 40px;
	width: 40px;
	position: absolute;
	right: 0;
	top: 0;
	transition: opacity 0.15s ease-in-out;
	@include zIndex(modalClose);

	@include media-breakpoint-up(md) {
		>div {
			height: 40px !important;
			width: 40px !important;

			>span {
				height: 40px !important;
			}
		}
	}
}

.content {
	padding: 24px;
}

.footer {
	border-radius: 0 0 2px 2px;
	background-color: #FAFAFA;
	padding: 4px 6px;
	height: 56px;
	width: 100%;
	text-align: right;

	a {
		margin: 6px 0;
	}
}

.fog {
	position: fixed;
	@include zIndex(modalFog);
	top: -25%;
	left: 0;
	bottom: 0;
	right: 0;
	height: 125%;
	width: 100%;
	background: rgba($black, 0.7);
	// display: none;

	will-change: opacity;
}
