@mixin noList {
	list-style: none;
	list-style-type: none;
	padding: 0;
	margin: 0;
}

@mixin absoluteFull {
	bottom: 0;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

@mixin flexCenter {
	align-items: center;
	display: flex;
	justify-content: center;
}

$browser-context: 18; // Default

@function em($pixels, $context: $browser-context) {
	@return #{$pixels/$context}em;
}

@function rem($size) {
	$remSize: $size / $browser-context;
	@return #{$remSize}rem;
}

@mixin fontSize($sizeValue: 1.6, $line: $sizeValue * 1.5) {
	font-size: $sizeValue * 1 + px !important;
	line-height: $line * 1 + px !important;
	font-size: $sizeValue / 16 + rem !important;
	line-height: $line / 16 + rem !important;
}

@mixin faIcon($icon) {
	content: $icon;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
}

@mixin headings() {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@content;
	}
}

@mixin arrowLink($showArrow: true) {
	@include fontSize(18, 24);
	color: $white;
	display: inline-block;
	font-family: $fontBase;
	font-weight: 700;
	padding: 0 35px 10px 1px;
	position: relative;

	@if $showArrow==true {
		&::before {
			color: $white;
			content: '\f30b';
			font-family: 'Font Awesome 5 Free';
			font-size: 20px;
			font-weight: 900;
			position: absolute;
			right: 3px;
			top: 0;
			transition: all 0.1s ease-in-out, right 0.1s ease-in-out;
		}
	}

	&::after {
		background-color: $green;
		bottom: 0;
		content: ' ';
		height: 3px;
		left: 0;
		position: absolute;
		transition: all 0.1s ease-in-out, width 0.1s ease-in-out;
		width: calc(100% - 35px);
	}

	&:hover {
		color: $white;

		// &::before {
		// 	color: $white;
		// 	right: 0;
		// }

		&::after {
			width: 100%;
		}
	}
}

@mixin titleUnderline($width, $maxWidth: 100%, $spacing: $spacingUnit) {
	padding-bottom: $spacing;
	position: relative;

	&::after {
		background-color: $green;
		bottom: 0;
		content: ' ';
		height: 1px;
		left: 0;
		margin: 0 auto;
		max-width: $maxWidth;
		position: absolute;
		right: 0;
		width: $width;
	}
}

@mixin headingBrand($xs: 40, $sm: 45, $md: 50, $lg: 55, $xl: 70) {
	line-height: 1;
	padding: 5px 0;

	@include headings() {
		@include fontSize($xs, $xs + 10);
		display: inline-block;
		color: $white;
		font-family: $fontHeading;
		font-weight: 700;
		font-size: inherit;
		line-height: 1;
		margin: 0;
		text-transform: uppercase;

		@include media-breakpoint-up(sm) {
			@include fontSize($sm, $sm + 10);
		}

		@include media-breakpoint-up(md) {
			@include fontSize($md, $md + 10);
		}

		@include media-breakpoint-up(lg) {
			@include fontSize($lg, $lg + 10);
		}

		@include media-breakpoint-up(xl) {
			@include fontSize($xl, $xl + 10);
		}
	}
}

@mixin pagePadding() {
	padding: $headerHeightMobile 0 0;

	@include media-breakpoint-up(md) {
		padding: $headerHeight 0 0;
	}
}
