@import '../../css/utils/utils';

.inner {
	padding: $spacingUnitLg 0;

	@include media-breakpoint-up(md) {
		padding: $spacingUnitXl 0;
	}

	@include media-breakpoint-up(lg) {
		padding: $spacingUnitXxl 0;
	}
}
