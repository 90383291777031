@import '../../css/utils/utils.scss';

.section {
	background-color: $blueDarkest;
	position: relative;
}

.contain {
	position: relative;
	@extend .py-4;
	@extend .py-md-5;
	@extend .py-lg-6;
	@extend .py-xl-7;
}

.tesimonials {
	max-width: 1176px;
	margin: 0 auto;
}

.quote {
	color: $blue;
	display: none;
	font-family: Georgia, 'Times New Roman', Times, serif;
	left: 0;
	position: absolute;
	top: 0;
	vertical-align: middle;

	@include media-breakpoint-up(md) {
		display: block;
		font-size: 200px;
		line-height: 100px;
	}

	@include media-breakpoint-up(xl) {
		font-size: 400px;
		left: -20px;
		line-height: 200px;
	}
}

.testimonial {
	@include fontSize(18, 28);
	background-color: rgba($white, 0.05);
	font-family: $fontBase;
	font-weight: 300;
	padding: em(10) em(30);

	@include media-breakpoint-up(md) {
		padding: em(35) em(55);
	}

	@include media-breakpoint-up(lg) {
		padding: em(45) em(65);
	}
}

.testimonialContent {
	@extend .mb-4;
	@extend .mb-md-5;
}

.testimonialName {
	font-weight: 700;
}

.testimonialSubtitle {
	font-style: italic;
}
