.btn {
	@include fontSize(16, 24);
	border: 1px solid transparent;
	cursor: pointer;
	font-family: $fontHeading;
	font-weight: 700;
	max-width: 100%;
	outline: none !important;
	padding: 7px 35px;
	text-align: center;
	transition: all 0.2s ease-in-out;
	white-space: nowrap;
}

.btn-outline {
	background-color: transparent;
	border: 1px solid #979797;
	color: #979797;
	font-weight: 400;

	&:hover {
		border-color: $white;
		color: $white;
	}
}

.btn-primary {
	background-color: $blue;
	border-color: $blue;
	color: $white;
	font-weight: 400;

	&:hover {
		background-color: transparent;
		border-color: $blue;
		color: $blue;
	}
}
