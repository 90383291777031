@import '../../css/utils/utils.scss';

.wrap {
	// padding: 45px 0;
}

.playerWrap {
	position: relative;
	padding-top: 56.25%;
	/* Player ratio: 100 / (1280 / 720) */
}

.playerWrapTall {
	position: realtive;
}

.player {
	position: absolute;
	top: 0;
	left: 0;
}
