$z-index: body,
sectionLineWrap,
sectionLines,
teamMemberImage,
teamMemberName,
heroImage,
heroOverlay,
heroLine,
heroContent,
heroScroll,
footer,
loading,
header,
searchTakeover,
offmenu,
offmenuFog,
offmenuWrap,
offmenuClose,
modalFog,
modalWrap,
modal,
modalClose;

@function z-index($key) {
	@return index($z-index, $key);
}

@mixin zIndex($key) {
	z-index: z-index($key);
}
