@import '../../css/utils/utils.scss';

.wrap {
	position: fixed;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
	@include zIndex(modalWrap);
}

.lightbox {
	// display: none;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	padding: 0;
	height: calc(100% - 40px);
	width: calc(100% - 5px);
	margin: auto;
	overflow: hidden;
	border-radius: 2px;
	will-change: top, opacity;
	@include zIndex(modal);

	@include media-breakpoint-up(md) {
		height: calc(100% - 40px);
		width: calc(100% - 40px);
	}
}

.close {
	color: $white;
	cursor: pointer;
	display: block;
	font-size: 40px;
	height: 40px;
	width: 40px;
	position: absolute;
	right: 0;
	top: 0;
	transition: opacity 0.15s ease-in-out;
	@include zIndex(modalClose);

	@include media-breakpoint-up(md) {
		>div {
			height: 40px !important;
			width: 40px !important;

			>span {
				height: 40px !important;
			}
		}
	}
}

.lightboxInner {
	height: 100%;
	width: 100%;
	position: relative;
}

.fog {
	position: fixed;
	@include zIndex(modalFog);
	top: -25%;
	left: 0;
	bottom: 0;
	right: 0;
	height: 125%;
	width: 100%;
	background: rgba($black, 0.7);
	// display: none;

	will-change: opacity;
}

.carousel {
	height: 100%;
	opacity: 0;
	padding: 10px;
	transition: opacity 0.2s ease-in-out;
	width: 100%;

	@include media-breakpoint-up(md) {
		padding: 40px;
	}

	>div {
		height: 100%;

		>div:first-child {
			height: 100%;

			>div {
				height: 100%;
			}
		}
	}
}

.carouselActive {
	opacity: 1;
}

.image {
	bottom: 0;
	display: block;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
}

.imageTall {
	max-height: 100%;
	width: auto;
}

.next,
.prev {
	@include fontSize(60, 60);
	background: none !important;
	color: rgba($white, 0.7);
	height: 60px !important;
	outline: none !important;
	transition: color 0.2s ease-in-out;

	&:hover {
		color: $white;
	}

	span {
		position: absolute;
	}
}

.pagination {
	bottom: 0;
	left: 0;
	margin: 0 auto;
	position: fixed;
	right: 0;
	width: 100%;

	@include media-breakpoint-up(md) {
		width: 50%;
	}

	>div {
		position: relative;
	}
}

.paginationBullet {
	background-color: rgba($white, 0.7);
	border-radius: 100%;
	cursor: pointer;
	display: inline-block;
	height: 12px;
	outline: none !important;
	margin: 0 6px;
	transition: background-color 0.2s ease-in-out;
	width: 12px;

	&:hover {
		color: $white;
	}

	@include media-breakpoint-up(md) {
		margin: 0 11px;
	}
}

.paginationBulletActive {
	background-color: $blue;
}
