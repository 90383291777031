@import '../../css/utils/utils';

@mixin heroHeight() {
	height: 400px;
	width: 100%;

	@include media-breakpoint-up(sm) {
		height: 75vh;
		min-height: 450px;
	}

	@include media-breakpoint-up(lg) {
		height: 100vh;
		max-height: 800px;
		min-height: 700px;
	}
}

.hero {
	@include heroHeight();
	display: block;
	position: relative;
	width: 100%;
}

.image {
	@include heroHeight();
	background-position: top center;
	background-size: cover;
	cursor: pointer;
}

.imageOverlay {
	@include absoluteFull();
}

.carousel {
	@include heroHeight();
	position: absolute;
}

.nav {
	bottom: calc(10vw - 52px);
	display: none;
	position: absolute;
	right: 10%;

	@include media-breakpoint-up(md) {
		display: block;
	}

	@include media-breakpoint-up(lg) {
		bottom: calc(11vw - 52px);
	}
}

.pagination {
	bottom: 50px;
	left: 0;
	margin: 0 auto;
	position: absolute;
	right: 0;
	width: 100%;

	@include media-breakpoint-up(md) {
		bottom: 75px;
		width: 50%;
	}

	> div {
		position: relative;
	}
}

.paginationBullet {
	background-color: $black;
	border-radius: 100%;
	cursor: pointer;
	display: inline-block;
	height: 12px;
	margin: 0 6px;
	width: 12px;

	@include media-breakpoint-up(md) {
		margin: 0 11px;
	}
}

.paginationBulletActive {
	background-color: $blue;
}

.playOverlay {
	@include absoluteFull();
	align-items: center;
	background-color: rgba($black, 0.3);
	display: flex;
	justify-content: center;
}

.content {
	@include absoluteFull();
	@include zIndex(heroContent);
	align-items: center;
	display: flex;
	justify-content: center;
	opacity: 0;
	transform: translate3d(0, 5px, 0);
}

.contentActive {
	opacity: 1;
	transform: translate3d(0, 0, 0);
	transition: all 0.3s;
}

.contentInner {
	color: $white;
	padding: 0 11px;
	text-align: center;

	h1,
	h2 {
		@include fontSize(50, 50);
		font-family: $fontHeading;
		font-weight: 700;
		margin: 0;
		text-transform: uppercase;

		@include media-breakpoint-up(md) {
			@include fontSize(70, 70);
		}

		@include media-breakpoint-up(lg) {
			@include fontSize(90, 90);
		}

		@include media-breakpoint-up(xl) {
			white-space: nowrap;
		}
	}

	h3,
	h4,
	h5,
	h6,
	p {
		@include fontSize(20, 24);
		font-weight: 300;
		letter-spacing: 2.3px;
		margin: 9px 0 0;
		text-transform: uppercase;

		@include media-breakpoint-up(md) {
			@include fontSize(23, 30);
			letter-spacing: 3px;
			white-space: nowrap;
		}

		@include media-breakpoint-up(lg) {
			@include fontSize(26, 39);
			letter-spacing: 3px;
			white-space: nowrap;
		}

		strong {
			font-weight: 700;
		}
	}
}

.scroll {
	bottom: 60px;
	cursor: pointer;
	display: none;
	height: 44px;
	left: 0;
	margin: 0 auto;
	opacity: 0.7;
	position: absolute;
	right: 0;
	transition: opacity 0.2s ease-in-out;
	width: 169px;
	@include zIndex(heroScroll);

	@include media-breakpoint-up(md) {
		display: block;
	}

	&:hover {
		opacity: 0.9;
	}
}

.scrollLine {
	background-color: $white;
	box-sizing: content-box;
	display: block;
	height: 15px;
	position: absolute;
	bottom: 11px;
	width: 50%;
}

.scrollLine:first-child {
	left: 2px;
	width: 50%;
}

.scrollLine:last-child {
	right: 3px;
	width: 50%;
}

// Modifiers
.heroPortfolio {
	[data-clip] {
		background-color: $blueDarkest;
		position: absolute;
		width: 100%;
		z-index: 1;
	}
}
