@import '../../css/utils/utils';

.play {
	@include flexCenter();
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background-color: transparent;
	border: 3px solid $white;
	opacity: 0.7;
	cursor: pointer;
	position: relative;
	margin: 30px;
	transition: all 0.4s ease-in-out;

	@include media-breakpoint-up(xs) {
		height: 75px;
		width: 75px;
	}

	@include media-breakpoint-up(sm) {
		height: 100px;
		width: 100px;
	}

	&:hover {
		opacity: 0.9;

		&::after {
			-webkit-animation: sonarEffect 1.3s ease-out 0.5s;
			-moz-animation: sonarEffect 1.3s ease-out 0.5s;
			animation: sonarEffect 1.3s ease-out 0.5s;
		}

		&::before {
			-webkit-animation: sonarEffect 1.3s ease-out 0s;
			-moz-animation: sonarEffect 1.3s ease-out 0s;
			animation: sonarEffect 1.3s ease-out 0s;
		}
	}

	&::before,
	&::after {
		pointer-events: none;
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		content: '';
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
	}
}

.icon {
	display: block;
	width: 0;
	height: 0;
	border-top: 20px solid transparent;
	border-bottom: 20px solid transparent;
	border-left: 24px solid rgba($white, 0.86);
	margin-left: 5px;
}

@keyframes sonarEffect {
	0% {
		opacity: 0.3;
	}

	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 2px 2px $white, 0 0 0 2px rgba(255, 255, 255, 0.5);
	}

	100% {
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 2px 2px $white, 0 0 0 2px rgba(255, 255, 255, 0.5);
		transform: scale(1.5);
		opacity: 0;
	}
}
