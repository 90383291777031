@import './utils.scss';

input[type='text'],
input[type='email'],
textarea {
	background-color: rgba($white, 0.14);
	border: 1px solid transparent;
	color: $white;
	padding: 10px 15px 12px;
	transition: all 0.15s ease-in-out;
	width: 100%;

	&:focus {
		background-color: rgba($white, 0.4);
		border-color: $blue;
		outline: none;
	}
}

textarea {
	height: 271px;
	resize: none;
}

label {
	@include fontSize(18, 26);
	display: block;
	font-family: $fontHeading;
	font-weight: 400;
	margin: 0 0 10px;
	text-transform: uppercase;
}
