@import '../../css/utils/utils.scss';

.section {
	background: linear-gradient(to bottom, $blueDarkest 0%, $blueDark 100%);
	position: relative;
}

.content {
	@extend .mb-5;
	@extend .mb-md-6;
}

.heading {
	@extend .mb-5;
	@extend .mb-md-0;
}

.videoHeading {
	@include headingBrand(40, 45, 37, 52, 63);
}

.allVideosLink {
	@include arrowLink();
}

.categories {
	@include noList();
	@extend .mb-4;
	@extend .px-2;
	display: flex;
	flex-wrap: wrap;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;

	&[data-align='center'] {
		justify-content: center;
		max-width: none;
	}
}

.category {
	@extend .mr-2;
	@extend .mb-2;

	@extend .mr-sm-3;

	@extend .mr-md-4;
	@extend .mb-md-4;

	@extend .mr-lg-6;

	&:last-child {
		@extend .mb-0;
	}
}

.categoryLink {
	@include fontSize(22, 32);
	cursor: pointer;
	font-family: $fontHeading;
	font-weight: 700;
	opacity: 1;
	padding: 6px 15px 9px;
	position: relative;
	transition: all 0.2s ease-in-out;
	text-transform: uppercase;

	&::after {
		background-color: $green;
		bottom: 0;
		content: ' ';
		height: 0;
		left: 0;
		position: absolute;
		transition: all 0.2s ease-in-out;
		width: 0;
	}

	&:hover {
		opacity: 0.7;
	}
}

.categoryLinkActive {
	@extend .categoryLink;

	&::after {
		height: 3px;
		width: 100%;
	}

	&:hover {
		opacity: 1;
	}
}

.loadMoreBtn {
	padding: 20px 15px;
	text-align: center;
}

.btn {
	margin: 0 auto;
	width: 177px;

	>* {
		display: block;
		margin: 0 auto;
	}
}
