@import '../../css/utils/utils.scss';

.wrap {
	margin: 0 auto;

	@include media-breakpoint-up(md) {
		max-width: 584px;
		margin: 0;
	}
}

.polygon {
	opacity: 0.6;
}

.heading {
	@include headingBrand(40, 45, 50, 55, 70);
}
