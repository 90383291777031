@import '../../css/utils/utils.scss';

.section {
	background-color: $blueDarkest;
	position: relative;
}

.inner {
	@extend .py-sm-4;
	@extend .py-md-5;
}

.header {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	max-width: 273px;

	@include media-breakpoint-up(sm) {
		max-width: none;
	}

	@include media-breakpoint-up(md) {
		align-items: flex-end;
	}
}

.heading {
	@extend .mb-3;
	@extend .mb-sm-4;
	width: 100%;

	@extend .mb-xl-6;

	@include media-breakpoint-up(sm) {
		margin-right: 50%;
		width: 50%;
	}

	@include media-breakpoint-up(xl) {
		order: 3;
		margin-left: 10%;
		margin-right: 0;
		width: 37%;
	}
}

.contact {
	@extend .mx-3;
	@extend .mb-4;
	width: 100%;

	@extend .mx-sm-0;

	@extend .mb-lg-6;

	@include media-breakpoint-up(sm) {
		width: 50%;
	}

	@include media-breakpoint-up(xl) {
		order: 2;
		width: 22%;
	}
}

.social {
	@extend .mx-3;
	@extend .mb-4;
	width: 100%;

	@extend .mx-sm-0;
	@extend .mb-lg-6;

	@include media-breakpoint-up(sm) {
		width: 50%;
	}

	@include media-breakpoint-up(xl) {
		order: 1;
		width: 25%;
	}
}

.contactItems {
	@include noList();
}

.contactItem {

	h3,
	h5 {
		@include fontSize(23, 32);
		font-family: $fontHeading;
		font-weight: 400;
		margin: 0;
	}

	h3 {
		color: $blue;
		text-transform: uppercase;
	}

	h5 {
		color: $white;
	}
}

.socialInner {
	max-width: 230px;
}

.socialIcons {
	@include noList();
	display: flex;
	flex-wrap: wrap;
}

.socialIcon {
	@include fontSize(32, 32);
	@extend .mx-1;
	@extend .mb-2;

	a {
		align-items: center;
		border: 1px solid $blue;
		color: $blue;
		display: flex;
		height: 66px;
		justify-content: center;
		transition: all 0.2s ease-in-out;
		width: 66px;

		&:hover {
			background-color: $blue;
			color: $white;
		}
	}
}

.form {
	ul {
		@include noList();
		display: flex;
		flex-wrap: wrap;

		li {
			@extend .mb-4;
			@extend .px-md-1;
			width: 100%;

			@include media-breakpoint-up(md) {
				width: calc(100% / 3);
			}

			&.full {
				width: 100%;
			}
		}
	}
}

.submitWrap {
	@include media-breakpoint-up(md) {
		display: flex;
		justify-content: flex-end;
	}

	button {
		@include fontSize(18, 26);
		@extend .py-3;
		@extend .mx-auto;
		@extend .mx-md-0;
		@extend .ml-md-auto;
		display: block;
		max-width: 231px;
		text-transform: uppercase;
		width: 80%;

		@include media-breakpoint-up(md) {
			width: 100%;
		}
	}
}

.notifications {
	display: flex;
	justify-content: center;

	@include media-breakpoint-up(md) {
		justify-content: flex-end;
	}
}

.notification {
	@include fontSize(16, 20);
	font-weight: 400;
	margin: 0;
}

.error {
	@extend .notification;
	color: $white;
}

.success {
	@extend .notification;
	color: $green;
}
