@import '../../css/utils/utils';

.counterBlocks {
	@include noList();
	display: flex;
	flex-wrap: wrap;

	@include media-breakpoint-up(sm) {
		flex-wrap: nowrap;
		justify-content: space-between;
	}
}

.counterBlock {
	text-align: center;
	@extend .mb-3;
	@extend .mb-sm-4;
	width: calc(100% / 3);
}

.counter {
	max-width: 320px;
	margin: 0 auto;
	padding: 0 5px;

	@include media-breakpoint-up(sm) {
		max-width: 360px;
		padding: 0 60px;
	}

	h3 {
		@include fontSize(50, 70);
		color: $green;
		margin: 0;

		@include media-breakpoint-up(sm) {
			@include fontSize(70, 90);
		}

		@include media-breakpoint-up(lg) {
			@include fontSize(100, 134);
		}
	}

	p {
		@include fontSize(18, 18);
		color: $white;
		font-weight: 700;
		margin: 0;

		@include media-breakpoint-up(sm) {
			@include fontSize(20, 20);
		}

		@include media-breakpoint-up(lg) {
			@include fontSize(24, 24);
		}
	}
}
