@import '../../css/utils/utils.scss';

.preview {
	position: relative;
}

.previewInner {
	border-radius: 0;
	box-shadow: 8px 8px 50px 0 rgba(0, 0, 0, 0.6);
	overflow: hidden;
}

.previewOverlay {
	@include absoluteFull();
	@include flexCenter();
	border-radius: 9px;
	background-color: rgba($black, 0.3);
	z-index: 1;
}
