@import '../../css/utils/utils.scss';

.preview {
	cursor: pointer;
	display: block;
	position: relative;
	overflow: hidden;

	&::before {
		content: none;
	}

	&:hover {
		.overlay {
			opacity: 0.4;
		}

		.content {
			border-color: $green;
		}
	}
}

.inner {
	padding: 4px;
	position: relative;
	text-align: center;
	user-select: none;
}

.image {
	@include absoluteFull();
	background-color: rgba($black, 0.4);
	z-index: -2;

	img {
		height: 100%;
		object-fit: cover;
		object-position: center center;
		width: 100%;
	}
}

.overlay {
	@include absoluteFull();
	background-color: rgba(0, 0, 0, 1);
	opacity: 0.3;
	transition: all 0.2s ease-in-out;
	z-index: -1;
}

.content {
	border: 2px solid transparent;
	transition: all 0.2s ease-in-out;
}

.contentInner {
	max-width: 298px;
	margin: 0 auto;
	padding: 55px 25px 52px;
}

.icon {
	color: $white;
	cursor: pointer;
	transition: color 0.15s ease-in-out;

	&:hover {
		color: $green;
	}
}

.iconVideo {
	@extend .icon;
	@include fontSize(48, 48);
	margin-bottom: 21px;
}

.iconPhoto {
	@extend .icon;
	@include fontSize(40, 40);
	margin-bottom: 37px;
}

.date {
	@include fontSize(16, 24);
	color: rgba($white, 0.74);
	display: block;
	font-family: $fontHeading;
	font-weight: 300;
	letter-spacing: 2px;
	margin: 0 0 12px;
}

.title {
	@include fontSize(28, 32);
	color: $white;
	font-family: $fontHeading;
	font-weight: 700;
	margin: 0 0 16px;
	text-transform: uppercase;
}

.location {
	@include fontSize(17, 33);
	display: block;
	color: rgba($white, 0.74);
	font-style: italic;
	margin: 0 0 32px;
}

.link {
	@include fontSize(15, 33);
	font-weight: 300;
	position: relative;
	padding-bottom: 17px;
	white-space: nowrap;

	&::after {
		content: ' ';
		background-color: $green;
		bottom: 0;
		border-radius: 4px;
		height: 3px;
		left: -2.5%;
		position: absolute;
		transition: all 0.5s ease-in-out;
		width: 105%;
	}

	&:hover {
		&::after {
			left: -10%;
			transition-duration: 0.2s;
			width: 120%;
		}
	}
}
