@import '../../css/utils/utils';

.footer {
	background-color: $blueDark;
	bottom: 0;
	height: $footerHeight + 1px;
	position: absolute;
	margin-top: -1px;
	width: 100%;
	@include zIndex(footer);
}

.inner {
	align-items: center;
	display: flex;
	height: 100%;
}

.copy {
	@include fontSize(13, 18);
	color: $grey;
}
