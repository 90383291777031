@import '../../css/utils/utils.scss';

.header {
	@include zIndex(header);
	background-color: rgba($black, 0.4);
	height: $headerHeightMobile;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;

	@include media-breakpoint-up(md) {
		height: $headerHeight;
	}
}

.inner {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: space-between;
	padding: 0 $spacingUnit;
	position: relative;
}

.menuWrap {
	align-items: center;
	display: flex;
	flex: 1;
	height: 100%;
	justify-content: center;
}

.search,
.hamburg {
	width: 33px;

	@include media-breakpoint-up(md) {
		width: 45px;
	}
}

.search {
	@include fontSize(20, 20);
	color: $white;
	cursor: pointer;

	span {
		opacity: 1;
		transition: opacity 0.15s ease-in-out;

		&:hover {
			opacity: 0.7;
		}
	}
}

.logo {
	height: 33px;
	width: 77px;
	text-align: center;

	@include media-breakpoint-up(md) {
		height: 60px;
		width: 154px;
	}

	@include media-breakpoint-up(lg) {
		height: 70px;
		width: 164px;
	}
}

.logoLink {
	display: block;
}

.menu {
	align-items: center;
	display: none;
	height: 100%;
	justify-content: space-between;

	@include media-breakpoint-up(md) {
		display: flex;
	}
}

.menuLeft {
	@extend .menu;

	.navLinks {
		justify-content: flex-end;

		li {
			margin-right: $spacingUnit * 2.2;

			@include media-breakpoint-up(lg) {
				margin-right: $spacingUnit * 4;
			}
		}
	}
}

.menuRight {
	@extend .menu;

	.navLinks {
		justify-content: flex-start;

		li {
			margin-left: $spacingUnit * 2.2;

			@include media-breakpoint-up(lg) {
				margin-left: $spacingUnit * 4;
			}
		}
	}
}

.navLinks {
	@include noList();
	align-items: center;
	display: flex;
	height: 100%;

	li {
		align-items: center;
		display: flex;
		height: 100%;
	}
}

.navLink {
	@include fontSize(16, 24);
	align-items: center;
	color: $white;
	display: flex;
	font-family: $fontHeading;
	font-weight: 400;
	height: 100%;
	opacity: 1;
	text-transform: uppercase;
	transition: opacity 0.15s ease-in-out;

	&:hover,
	&:focus {
		color: $white;
	}

	&:hover {
		opacity: 0.7;
	}
}
