@import '../../css/utils/utils';

.section {
	position: relative;
}

.inner {
	padding: $spacingUnitLg 0;

	@include media-breakpoint-up(md) {
		padding: $spacingUnitXl 0;
	}

	@include media-breakpoint-up(lg) {
		padding: $spacingUnitXxl 0;
	}
}

.heading,
.meta {
	margin: 0 0 $spacingUnit;
}

.heading {
	@include media-breakpoint-up(lg) {
		@include headings() {
			@include fontSize(60, 70);
		}
	}

	@include media-breakpoint-up(xl) {
		@include headings() {
			@include fontSize(60, 70);
		}
	}
}

.metaList {
	@include noList();
}

.metaBlock {
	margin-bottom: $spacingUnit;

	@include media-breakpoint-up(sm) {
		margin-bottom: $spacingUnit;
	}

	@include media-breakpoint-up(md) {
		margin-bottom: $spacingUnitMd;
	}

	@include media-breakpoint-up(lg) {
		margin-bottom: $spacingUnitLg;
	}
}

.metaTitle,
.metaText {
	@include fontSize(24, 30);
	font-family: $fontHeading;
	font-weight: 400;
	margin: 0;
	text-transform: uppercase;

	@include media-breakpoint-up(sm) {
		@include fontSize(24, 30);
	}

	@include media-breakpoint-up(lg) {
		@include fontSize(32, 47);
	}
}

.metaTitle {
	color: $blue;
}

.metaText {
	color: $white;
}

.content {
	@include fontSize(18, 28);
	font-weight: 400;

	margin: $spacingUnit 0 0;

	@include media-breakpoint-up(sm) {
		margin: $spacingUnitMd 0 0;
	}

	@include media-breakpoint-up(md) {
		margin: $spacingUnitLg 0 0;
	}

	@include media-breakpoint-up(lg) {
		margin: $spacingUnitXl 0 0;
	}
}
